// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-correspondence-jsx": () => import("./../../../src/pages/correspondence.jsx" /* webpackChunkName: "component---src-pages-correspondence-jsx" */),
  "component---src-pages-grand-hotel-fantasia-entry-jsx": () => import("./../../../src/pages/grand-hotel-fantasia-entry.jsx" /* webpackChunkName: "component---src-pages-grand-hotel-fantasia-entry-jsx" */),
  "component---src-pages-grande-hotel-fantasia-part-1-page-jsx": () => import("./../../../src/pages/grande-hotel-fantasia-part1-page.jsx" /* webpackChunkName: "component---src-pages-grande-hotel-fantasia-part-1-page-jsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-memory-path-1-jsx": () => import("./../../../src/pages/memory-path1.jsx" /* webpackChunkName: "component---src-pages-memory-path-1-jsx" */),
  "component---src-pages-memory-path-1-page-jsx": () => import("./../../../src/pages/memory-path1-page.jsx" /* webpackChunkName: "component---src-pages-memory-path-1-page-jsx" */),
  "component---src-pages-memory-path-3-jsx": () => import("./../../../src/pages/memory-path3.jsx" /* webpackChunkName: "component---src-pages-memory-path-3-jsx" */),
  "component---src-pages-memory-path-3-page-jsx": () => import("./../../../src/pages/memory-path3-page.jsx" /* webpackChunkName: "component---src-pages-memory-path-3-page-jsx" */),
  "component---src-pages-pages-jsx": () => import("./../../../src/pages/pages.jsx" /* webpackChunkName: "component---src-pages-pages-jsx" */),
  "component---src-pages-photography-jsx": () => import("./../../../src/pages/photography.jsx" /* webpackChunkName: "component---src-pages-photography-jsx" */),
  "component---src-pages-poetry-jsx": () => import("./../../../src/pages/poetry.jsx" /* webpackChunkName: "component---src-pages-poetry-jsx" */),
  "component---src-pages-publications-jsx": () => import("./../../../src/pages/publications.jsx" /* webpackChunkName: "component---src-pages-publications-jsx" */),
  "component---src-pages-thoughts-jsx": () => import("./../../../src/pages/thoughts.jsx" /* webpackChunkName: "component---src-pages-thoughts-jsx" */),
  "component---src-templates-blog-post-contentful-js": () => import("./../../../src/templates/blog-post-contentful.js" /* webpackChunkName: "component---src-templates-blog-post-contentful-js" */),
  "component---src-templates-correspondence-page-contentful-jsx": () => import("./../../../src/templates/correspondence-page-contentful.jsx" /* webpackChunkName: "component---src-templates-correspondence-page-contentful-jsx" */),
  "component---src-templates-gallery-page-contentful-jsx": () => import("./../../../src/templates/gallery-page-contentful.jsx" /* webpackChunkName: "component---src-templates-gallery-page-contentful-jsx" */),
  "component---src-templates-grande-hotel-fantasia-entry-contentful-jsx": () => import("./../../../src/templates/grande-hotel-fantasia-entry-contentful.jsx" /* webpackChunkName: "component---src-templates-grande-hotel-fantasia-entry-contentful-jsx" */),
  "component---src-templates-memory-path-1-page-contentful-jsx": () => import("./../../../src/templates/memory-path1-page-contentful.jsx" /* webpackChunkName: "component---src-templates-memory-path-1-page-contentful-jsx" */),
  "component---src-templates-memory-path-3-page-contentful-jsx": () => import("./../../../src/templates/memory-path3-page-contentful.jsx" /* webpackChunkName: "component---src-templates-memory-path-3-page-contentful-jsx" */),
  "component---src-templates-poetry-page-contentful-jsx": () => import("./../../../src/templates/poetry-page-contentful.jsx" /* webpackChunkName: "component---src-templates-poetry-page-contentful-jsx" */),
  "component---src-templates-publications-page-contentful-jsx": () => import("./../../../src/templates/publications-page-contentful.jsx" /* webpackChunkName: "component---src-templates-publications-page-contentful-jsx" */),
  "component---src-templates-thoughts-page-contentful-jsx": () => import("./../../../src/templates/thoughts-page-contentful.jsx" /* webpackChunkName: "component---src-templates-thoughts-page-contentful-jsx" */)
}

