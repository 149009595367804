import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { renderOption } from "../utils/contentful-options"
import HeroImage from "../components/HeroImage"

export default function contact({ data }) {
  const title = data.allContentfulContactPage.edges[0].node.title
  const content = data.allContentfulContactPage.edges[0].node.content.json
  const heroImage =
    data.allContentfulContactPage.edges[0].node.heroImage.file.url

  return (
    <Layout>
      <SEO title="Contact" />

      <HeroImage bgImage={heroImage}>
        <h1 data-sal="fade" data-sal-easing="ease">
          {title}
        </h1>
      </HeroImage>
      <ContentContainer data-sal="fade" data-sal-easing="ease">
        <section style={{ marginTop: "30px" }}>
          {documentToReactComponents(content, renderOption)}
        </section>
      </ContentContainer>
    </Layout>
  )
}

export const contactPageQuery = graphql`
  query {
    allContentfulContactPage {
      edges {
        node {
          content {
            json
          }
          title
          heroImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`
