import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ContentContainer } from "../components/Theme"

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Container = styled.div`
  color: black;
  box-shadow: none;
  margin: 10px 10px 10px 0;
  width: 350px;
  height: 160px;
  padding: 30px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  h3 {
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    margin: 0 0 5px 0;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  &:hover {
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
  }
`

export default function correspondence({ data }) {
  const allCorrespondence = data.allContentfulCorrespondenceEntry.edges

  return (
    <Layout>
      <SEO title="Correspondence" />
      <ContentContainer>
        <h1>Correspondence</h1>
        <Wrapper>
          {allCorrespondence?.map(({ node }) => {
            const title = node.title
            const subtitle = node.subtitle
            return (
              <Link
                key={node.slug}
                to={`/${node.slug}`}
                style={{ boxShadow: `none` }}
              >
                <Container>
                  <h3>{title}</h3>
                  <p>{subtitle}</p>
                </Container>
              </Link>
            )
          })}
        </Wrapper>
      </ContentContainer>
    </Layout>
  )
}

export const thoughtsPageQuery = graphql`
  query {
    allContentfulCorrespondenceEntry(
      sort: { fields: [createdAt], order: ASC }
    ) {
      edges {
        node {
          slug
          content {
            content
          }
          title
          subtitle
        }
      }
    }
  }
`
